import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Footer from "../../components/footer2Versao";
import "./index.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuCadastro from "./menuPlanosCadastro";
import Image from "react-bootstrap/Image";
import Logo from "../../img/logo2x.png";
import { HashLink as Link } from "react-router-hash-link";
import ViaCep from "react-via-cep";
import InputMask from "react-input-mask";
import swal from "sweetalert";
import {
  urlPessoas,
  entregarIdLogado,
  entregarPessoaLogada,
  valorPlanoEscolhido,
  periodoEscolhido,
  frete,
  token,
} from "../../servicos/pessoas-service";
import { urlPagamentos } from "../../servicos/pagamentos-service";
import { assinaturaLogada } from "../../servicos/assinaturas-service";
import { Redirect } from "react-router";

class Pagamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valorCobrar: valorPlanoEscolhido + frete,
    };
    this.cadastrarPagamento = this.cadastrarPagamento.bind(this);
    this.pegarBandeiraCartao = this.pegarBandeiraCartao.bind(this);
    this.calcularValorACobrar = this.calcularValorACobrar.bind(this);
    this.configurarExpiraEm = this.configurarExpiraEm.bind(this);
    this.cardEcripted = this.cardEcripted.bind(this);
    this.configurarTelefone = this.configurarTelefone.bind(this);
  }

  pegarBandeiraCartao(cardnumber) {
    var cardnumber = cardnumber.replace(/[^0-9]+/g, "");

    var cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^5[1-5][0-9]{14}/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      amex: /^3[47][0-9]{13}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    };

    for (var flag in cards) {
      if (cards[flag].test(cardnumber)) {
        return flag;
      }
    }

    return false;
  }

  calcularValorACobrar(plano, frete) {
    let promocao = document.getElementById("formCadastro").promocao.value;

    if (promocao === "promo100ltstart") {
      this.setState({ valorCobrar: 0 });
      return 0;
    } else {
      this.setState({ valorCobrar: plano + frete });

      return plano + frete;
      // return 201.55;
    }
  }

  async cadastrarPagamento() {
    let formOk = false;
    let nomeTitular = document.getElementById("formCadastro").nomeCartao.value;
    let cpfTitular = document.getElementById("formCadastro").cpfCartao.value;
    let numeroCartao = document.getElementById("formCadastro").numCartao.value;
    let espiraEm = document.getElementById("formCadastro").expiraEm.value;
    let codigoSeguranca =
      document.getElementById("formCadastro").codigoSeguranca.value;
    let bandeiraCartao = this.pegarBandeiraCartao(numeroCartao);
    let dataNascimento =
      document.getElementById("formCadastro").dataNascimento.value;

    let telefone = document.getElementById("formCadastro").telefone.value;

    let enderecoCep = document.getElementById("formCadastro").enderecoCep.value;
    let enderecoPais =
      document.getElementById("formCadastro").enderecoPais.value;
    let enderecoEstado =
      document.getElementById("formCadastro").enderecoEstado.value;
    let enderecoCidade =
      document.getElementById("formCadastro").enderecoCidade.value;
    let enderecoBairro =
      document.getElementById("formCadastro").enderecoBairro.value;
    let enderecoRua = document.getElementById("formCadastro").enderecoRua.value;
    let enderecoNumero =
      document.getElementById("formCadastro").enderecoNumero.value;
    let enderecoComplemento =
      document.getElementById("formCadastro").enderecoComplemento.value;
    let promocao = document.getElementById("formCadastro").promocao.value;

    if (!promocao || promocao !== "promo100ltstart") {
      let cartaoEncriped = "";

      document.querySelector("#formCadastro").childNodes.forEach((el) => {
        el.lastChild.style.borderColor = "#b29dca";
        if (el.lastChild.name && el.lastChild.name != "promocao") {
          if (!el.lastChild.value) {
            el.lastChild.style.borderColor = "red";
            formOk = false;
          } else {
            formOk = true;
          }
        }
      });
      if (formOk) {
        let encripted = "";
        await fetch(urlPagamentos + "obterChavePublica", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((resposta) => resposta.json())
          .then((resultado) => {
            let chaveRecebida = JSON.parse(
              resultado.chavePublicaResponse.public_key
            );
            let key = chaveRecebida.public_key;
            cartaoEncriped = {
              publicKey: key,
              holder: nomeTitular,
              number: numeroCartao.replace(/[-]+/g, ""),
              expMonth: this.configurarExpiraEm(true, espiraEm),
              expYear: this.configurarExpiraEm(false, espiraEm),
              securityCode: codigoSeguranca,
            };

            encripted = window.PagSeguro.encryptCard(cartaoEncriped);
          });

        if (encripted.errors.hasErrors) {
          console.log(encripted);
        }

        setTimeout(() => {
          let bodyPagamento = {
            idAssinante: entregarIdLogado,
            // idAssinante: 15,
            assinaturaPagamentoId: assinaturaLogada,
            // assinaturaPagamentoId: 10,
            isCustomerBillPaymentService: true,
            pagamentoAutorizado: false,
            payment: {
              type: "CreditCard",
              amount: parseInt(
                this.calcularValorACobrar(valorPlanoEscolhido, frete)
                  .toFixed(2)
                  .replace(/[.]+/g, "")
              ),
              installments: 1,
              softDescriptor: `Assinatura LeituraTua ${assinaturaLogada}`,
              creditCard: {
                encrypted: encripted["encryptedCard"],
                number: numeroCartao.replace(/[-]+/g, ""),
                holder: {
                  nome: nomeTitular,
                  cpf: cpfTitular.replace(/[-,.]+/g, ""),
                  dataNascimento: dataNascimento,
                  ddd: this.configurarTelefone(telefone, true),
                  telefone: this.configurarTelefone(telefone, false).replace(
                    /[-]+/g,
                    ""
                  ),
                  endereco: {
                    rua: enderecoRua,
                    numero: enderecoNumero,
                    bairro: enderecoBairro,
                    complemento: enderecoComplemento,
                    cidade: enderecoCidade,
                    estado: enderecoEstado,
                    pais: enderecoPais,
                    cep: enderecoCep,
                  },
                },
                expirationDateYear: this.configurarExpiraEm(true, espiraEm),
                expirationDateMonth: this.configurarExpiraEm(false, espiraEm),
                securityCode: codigoSeguranca,
                brand: bandeiraCartao,
                cardOnFile: {
                  usage: "First",
                  reason: "Installments",
                },
              },
            },
          };

          fetch(urlPagamentos + "Pagar", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(bodyPagamento),
          })
            .then((resposta) => resposta.json())
            .then((resultado) => {
              if (resultado.mensagem) {
                swal(resultado.mensagem);
              } else {
                if (resultado.detalhePagamento) {
                  if (
                    resultado.detalhePagamento.retornoPagamento.includes(
                      "Pagamento Recusado"
                    )
                  ) {
                    swal("Não foi possível concluir o pagamento");
                  } else {
                    this.setState({ redirecionar: true });
                  }
                } else {
                  this.setState({ redirecionar: true });
                }
              }
            })
            .catch((err) => {
              console.log("Erro ao se conectar ao servidor: " + err);
            });
        }, 1000);
      }
    } else if (promocao === "promo100ltstart" && !formOk) {
      this.setState({ redirecionar: true });
    }
  }

  configurarExpiraEm(anoOuMes, dataRecebida) {
    if (anoOuMes) {
      return dataRecebida.slice(0, 2);
    } else {
      return dataRecebida.slice(3);
    }
  }

  configurarTelefone(telefone, ddd) {
    if (ddd) {
      return telefone.slice(0, 2);
    } else {
      return telefone.slice(3);
    }
  }

  async cardEcripted(cardNumber, holder, expCard, securityCode) {
    let key = "";

    await fetch(urlPagamentos + "obterChavePublica", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resposta) => resposta.json())
      .then((resultado) => {
        let chaveRecebida = JSON.parse(
          resultado.chavePublicaResponse.public_key
        );
        key = chaveRecebida.public_key;
        let cartaoEncriped = {
          publicKey: key,
          holder: holder,
          number: cardNumber.replace(/[-]+/g, ""),
          expMonth: this.configurarExpiraEm(true, expCard),
          expYear: this.configurarExpiraEm(false, expCard),
          securityCode: securityCode,
        };

        let encripted = window.PagSeguro.encryptCard(cartaoEncriped);
        console.log(encripted["encryptedCard"]);

        return encripted;
      });
  }

  render() {
    if (this.state.redirecionar == true) {
      return <Redirect to="/Obrigado" />;
    } else {
      return (
        <Container fluid id="inteiro">
          <Container fluid id="cadastroInteiro">
            <Container fluid id="headerCadastro">
              <Row id="cadastroInteiro">
                <Col xs={6} md={4} id="headerCadastroEsquerda">
                  <Link to="/">
                    <Image
                      src={Logo}
                      width="127"
                      height="37"
                      fluid
                      id="imgLogo2"
                    />
                  </Link>
                </Col>
                <Col xs={12} md={8} id="headerCadastroDireita">
                  <Row id="setas">
                    <Container id="caixaSetas">
                      <div id="setaInversaCimaSelec" />
                      <div id="setaInversaBaixoSelec" />
                      <div id="rectangleSelec">
                        <p id="escritoSetaPlanoSelec">passo 01</p>
                        <p id="escritoSetaDescricaoSelec">Planos</p>
                      </div>
                      <div id="setaSelec" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCimaSelec2" />
                      <div id="setaInversaBaixoSelec2" />
                      <div id="rectangleSelec2">
                        <p id="escritoSetaPlanoSelec">passo 02</p>
                        <p id="escritoSetaDescricaoSelec">Dados</p>
                      </div>
                      <div id="setaSelec2" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCimaSelec3" />
                      <div id="setaInversaBaixoSelec3" />
                      <div id="rectangleSelec3">
                        <p id="escritoSetaPlanoSelec">passo 03</p>
                        <p id="escritoSetaDescricaoSelec">Endereço</p>
                      </div>
                      <div id="setaSelec3" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCimaSelec4" />
                      <div id="setaInversaBaixoSelec4" />
                      <div id="rectangleSelec4">
                        <p id="escritoSetaPlanoSelec">passo 04</p>
                        <p
                          id="escritoSetaDescricaoSelec"
                          style={{ marginLeft: "-2px" }}
                        >
                          Questionário
                        </p>
                      </div>
                      <div id="setaSelec4" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCimaSelec5" />
                      <div id="setaInversaBaixoSelec5" />
                      <div id="rectangleSelec5">
                        <p id="escritoSetaPlanoSelec">passo 05</p>
                        <p id="escritoSetaDescricaoSelec">Pagamento</p>
                      </div>
                      <div id="setaSelec5" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCima" />
                      <div id="setaInversaBaixo" />
                      <div id="rectanglePonta">
                        <p id="escritoSetaObrigado">Obrigado!</p>
                      </div>
                    </Container>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid id="conteudoCadastroInteiro">
            <Container fluid id="conteudoCadastro">
              <Container
                fluid
                id="monteSeuPlano"
                style={{ marginBottom: "50px" }}
              >
                05 - Escolha a forma de pagamento.
              </Container>

              <Container className="questionario">
                <Container fluid id="monteSeuPlano">
                  <form
                    method="post"
                    id="formCadastro"
                    style={{ padding: "0", margin: "0 0 0 162px" }}
                  >
                    <Container style={{ padding: "0", margin: "0 0 50px 0" }}>
                      <h4>
                        Valor do seu plano: R$ {valorPlanoEscolhido.toFixed(2)}{" "}
                        mensais
                      </h4>
                      <h4>Frete para sua região: R$ {frete.toFixed(2)} </h4>
                    </Container>
                    <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                      <label htmlFor="numCartao" id="formLabel">
                        Número do cartão:
                      </label>
                      <InputMask
                        mask="9999-9999-9999-9999"
                        value={this.props.value}
                        onChange={this.props.onChange}
                        type="text"
                        name="numCartao"
                        id="formInput"
                      />
                    </Col>
                    <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                      <label htmlFor="nomeCartao" id="formLabel">
                        Nome do titular (Como escrito no cartão):
                      </label>
                      <input
                        type="text"
                        name="nomeCartao"
                        id="formInput"
                        size="29"
                      />
                    </Col>
                    <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                      <label htmlFor="cpfCartao" id="formLabel">
                        CPF do titular:
                      </label>
                      <InputMask
                        mask="999.999.999-99"
                        value={this.props.value}
                        onChange={this.props.onChange}
                        type="text"
                        name="cpfCartao"
                        id="formInput"
                        size="22"
                      />
                    </Col>

                    <Container id="inputEsquerda" style={{ padding: "0" }}>
                      <label for="expiraEm" id="formLabel">
                        Expira em:
                      </label>
                      <InputMask
                        mask="99/9999"
                        value={this.props.value}
                        onChange={this.props.onChange}
                        type="text"
                        name="expiraEm"
                        id="formInput"
                        size="8"
                        style={{ width: "30%", float: "none" }}
                      />
                    </Container>

                    <Container id="inputEsquerda" style={{ padding: "0" }}>
                      <label for="codigoSeguranca" id="formLabel">
                        Código de segurança:
                      </label>
                      <InputMask
                        mask="999"
                        type="text"
                        id="formInput"
                        name="codigoSeguranca"
                        size="10"
                        style={{ width: "30%", float: "none" }}
                      />
                    </Container>

                    <Container id="inputEsquerda" style={{ padding: "0" }}>
                      <label htmlFor="telefone" id="formLabel">
                        Telefone do titular do cartao:
                      </label>
                      <InputMask
                        mask="99 99999-9999"
                        value={this.props.value}
                        onChange={this.props.onChange}
                        type="text"
                        name="telefone"
                        id="formInput"
                        size="15"
                        style={{ width: "48%", float: "none" }}
                      />
                    </Container>
                    <Container id="inputEsquerda" style={{ padding: "0" }}>
                      <label htmlFor="qtdParcelas" id="formLabel">
                        Data Nascimento:
                      </label>
                      <InputMask
                        mask="99/99/9999"
                        value={this.props.value}
                        onChange={this.props.onChange}
                        type="text"
                        name="dataNascimento"
                        id="formInput"
                        size="15"
                        style={{ width: "38%", float: "none" }}
                      />
                    </Container>
                    <Container style={{ padding: "0", margin: "0 0 0 -15px" }}>
                      <h4>Endereço:</h4>
                    </Container>

                    <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                      <label htmlFor="enderecoRua" id="formLabel">
                        Rua:
                      </label>
                      <input type="text" name="enderecoRua" id="formInput" />
                    </Col>
                    <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                      <label htmlFor="enderecoNumero" id="formLabel">
                        numero:
                      </label>
                      <input
                        type="text"
                        name="enderecoNumero"
                        id="formInput"
                        style={{ width: "28%", float: "none" }}
                      />
                    </Col>
                    <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                      <label htmlFor="enderecoComplemento" id="formLabel">
                        Complemento:
                      </label>
                      <input
                        type="text"
                        name="enderecoComplemento"
                        id="formInput"
                        style={{
                          width: "38%",
                          float: "none",
                          marginLeft: "50px",
                        }}
                      />
                    </Col>
                    <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                      <label htmlFor="enderecoBairro" id="formLabel">
                        Bairro:
                      </label>
                      <input type="text" name="enderecoBairro" id="formInput" />
                    </Col>
                    <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                      <label htmlFor="enderecoCidade" id="formLabel">
                        Cidade:
                      </label>
                      <input type="text" name="enderecoCidade" id="formInput" />
                    </Col>
                    <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                      <label htmlFor="enderecoEstado" id="formLabel">
                        Estado:
                      </label>
                      <input type="text" name="enderecoEstado" id="formInput" />
                    </Col>
                    <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                      <label htmlFor="enderecoPais" id="formLabel">
                        Pais:
                      </label>
                      <input type="text" name="enderecoPais" id="formInput" />
                    </Col>
                    <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                      <label htmlFor="enderecoCep" id="formLabel">
                        Cep:
                      </label>
                      <input type="text" name="enderecoCep" id="formInput" />
                    </Col>

                    <Container style={{ padding: "0", margin: "50px 0 0 0" }}>
                      <Col
                        xs={12}
                        md={12}
                        style={{ padding: "0", margin: "0" }}
                      >
                        <label htmlFor="promocao" id="formLabel">
                          Codigo Promocional:
                        </label>
                        <input
                          type="text"
                          name="promocao"
                          id="formInput"
                          style={{ marginBottom: "50px" }}
                          onChange={this.calcularValorACobrar}
                        />
                      </Col>
                      <h4>
                        Valor Total: R${" "}
                        {this.state.valorCobrar
                          ? Number(this.state.valorCobrar).toFixed(2)
                          : 0}
                      </h4>
                    </Container>
                  </form>
                </Container>
              </Container>

              <Container id="botaoCadastro">
                <ButtonGroup id="menuBotaoAssineMesesCadastro">
                  <Button variant="primary" id="botaoCadastroVoltar">
                    <Link to="/Questionario">
                      <h6>Voltar</h6>
                    </Link>
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoCadastroAvancar"
                    onClick={this.cadastrarPagamento}
                  >
                    <h6 id="textoBotaoAvancar">Próximo</h6>
                  </Button>
                </ButtonGroup>
              </Container>
            </Container>
          </Container>
          <Footer />
        </Container>
      );
    }
  }
}

export default Pagamento;
